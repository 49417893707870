@font-face {
  font-family: "Proba Pro";
  /* src: url(https://db.onlinewebfonts.com/t/06b277136976f20438c2ea811c45a4b0.woff2) */
  src: url(../src/assets/fonts/ProbaPro.woff2) format("woff2");
}

@font-face {
  font-family: "RockStarBold";
  src: url(../src/assets/fonts/RockStar-Bold.woff) format("woff");
}

@font-face {
  font-family: "RockStarMedium";
  src: url(../src/assets/fonts/RockStar-Medium.woff) format("woff");
}

@font-face {
  font-family: "ProbaPro";
  src: url(../src/assets/fonts/ProbaPro.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProBold";
  src: url(../src/assets/fonts/ProbaPro-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProBoldItalic";
  src: url(../src/assets/fonts/ProbaPro-BoldItalic.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProExtraLight";
  src: url(../src/assets/fonts/ProbaPro-ExtraLight.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProExtraLightItalic";
  src: url(../src/assets/fonts/ProbaPro-ExtraLightItalic.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProItalic";
  src: url(../src/assets/fonts/ProbaPro-Italic.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProLight";
  src: url(../src/assets/fonts/ProbaPro-Light.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProLightItalic";
  src: url(../src/assets/fonts/ProbaPro-LightItalic.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProMedium";
  src: url(../src/assets/fonts/ProbaPro-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProMediumItalic";
  src: url(../src/assets/fonts/ProbaPro-MediumItalic.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProRegular";
  src: url(../src/assets/fonts/ProbaPro-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProSemiBold";
  src: url(../src/assets/fonts/ProbaPro-SemiBold.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProSemiBoldItalic";
  src: url(../src/assets/fonts/ProbaPro-SemiBoldItalic.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProThin";
  src: url(../src/assets/fonts/ProbaPro-Thin.woff2) format("woff2");
}

@font-face {
  font-family: "ProbaProThinItalic";
  src: url(../src/assets/fonts/ProbaPro-ThinItalic.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: inherit;
}
